import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/nestor.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/pedromeireles.jpg" // Tell webpack this JS file uses this image
import pal14 from "../images/palestrantes/robertobarcellos.png" // Tell webpack this JS file uses this image

import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/westpoint.jpg" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/atm.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto22.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/foto23.jpg" // Tell webpack this JS file uses this image
import foto8 from "../images/fotoscursos/foto24.jpg" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';
import UseAnimations from 'react-useanimations';
var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Pecuária Nespresso:</span> <br></br>
              Qualidade de carne de bovino<br></br>
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal14} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                     Roberto Barcellos
                      </div>
                    
                    <div className="titulo inter-light">
                     Consultor e especialista em marcas de carne
                      </div>
                      </div>
                    
                  </div>
            

            
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                       17 e 18 de Julho de 2025
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                     Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      500€ + IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/pecuaria-nespresso.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/5Ajf2qGggGQXaQQw6" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              O curso Pecuária Nespresso é um programa que engloba uma visão
nova sobre a produção de carne bovina. Desde o campo,
implementação de marcas de carne, comportamento do
consumidor, alterações na indústria e o marketing necessário para
atrair o cliente certo.
                </div>
                <div className="logos">
                  
                {/* <img src={logo5} alt="" />
                <img src={logo6} alt="" /> */}

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Os cursos intensivos FarmIN têm um porgrama detalhado que possibilita ao participante observar as diversas áreas abrangidas pelo curso, os diferentes módulos e a atividade dos palestrantes ao longo do dia. 
          
            </div>
      </div>
      <div className="wrapper-program">





      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 17/07/2025 
                            </Accordion.Header>

                            <Accordion.Body>
                               
                                <div class="modulo">
                                <p><span className="date">09:00 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Carne do dia-a-dia VS Carne de Qualidade
                                Cenário </span></p>
                                </div> 
                                <div class="modulo">
                                <p><span className="date">10:00 - 10:45</span> | <span class="mod">Módulo 2</span> - <span class="txt">Pecuária Nespresso – Beef Design thinking</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:45 - 11:15</span> | <span className="mod">Coffee break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:15 - 12:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Perfil do novo consumidor  </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">12:30 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">Perfil do retalho</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:00 - 16:00</span> | <span className="mod">Módulo 5</span> - <span class="txt">O protocolo de uma marca de carne superior</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 16:45</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:45 - 17:30</span> | <span className="mod">Módulo 6</span> - <span class="txt">Processo industrial </span></p>
                                </div>
                                
                                {/* <div class="modulo">
                                  <p><span className="date">19:30</span> | <span className="mod">Degustação dos cortes concebidos</span></p>
                                </div> */}
                
                                </Accordion.Body>

                                </Accordion.Item>


                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                              
                            + Dia 2 - 18/07/2025 
                            </Accordion.Header>

                            <Accordion.Body>
                               
                                <div class="modulo">
                                <p><span className="date">09:30 - 10:30</span> | <span class="mod">Módulo 1</span> - <span class="txt"> Maturação - Alcançando a diferença
                               </span></p>
                                </div> 
                                 
                                <div class="modulo">
                                  <p><span className="date">10:30 - 11:00</span> | <span className="mod">Coffee break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:00 - 12:30</span> | <span className="mod">Módulo 2</span> - <span class="txt">Sabor das Índias: caso de sucesso  </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">12:30 - 13:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:30 - 14:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Maturação Dry Aged e Wet Aged</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">Aula de parilla e acompanhamentos</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 16:00</span> | <span className="mod">Encerramento</span></p>
                                </div>
                             
                                
                               
                
                                </Accordion.Body>

                                </Accordion.Item>
  
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/pecuaria-nespresso.pdf" target="_blank" >
                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                
                <div className="form">
                <a href="https://forms.gle/5Ajf2qGggGQXaQQw6" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal14} alt="" />

              </div>
              <div className="nome">
              Roberto Barcellos
              </div>
              <div className="titulo">
                 Consultor e detentor de marcas de carne
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  CEO - BBQ Secrets beef

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Diretor da Beef and veal consultoria
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  CEO da Confraria da carne (Boutique de carne de qualidade)
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Sócio do Restaurantes Bbq company

</p>
<p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Desenvolveu o programa Fisher Beef

</p>

                  

                 
                  </div>
              </div>
            </div>
        </div>
        </div>
     

   
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
